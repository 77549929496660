import axios from "axios";

export const fetchProducts = async () => {
  try {
    const headers = {
      Authorization: `Bearer ${process.env.REACT_APP_API_KEY}`,
    };

    const response = await axios.get(`${process.env.REACT_APP_BACKEND_URL}`, {
      headers,
    });

    return response.data.data;
  } catch (error) {
    throw error;
  }
};
