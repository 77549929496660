import React from "react";
import { useTranslation } from "react-i18next";

import "./language-selector.css";

function LanguageSelector() {
  const { i18n } = useTranslation("global");

  const changeLanguage = (language) => {
    i18n.changeLanguage(language);
  };

  const languages = [
    { code: "en", name: "En", flag: "https://www.kidlink.org/icons/f0-gb.gif" },
    { code: "de", name: "De", flag: "https://www.kidlink.org/icons/f0-de.gif" },
    { code: "fr", name: "Fr", flag: "https://www.kidlink.org/icons/f0-fr.gif" },
    { code: "ro", name: "Ro", flag: "https://www.kidlink.org/icons/f0-ro.gif" },
  ];

  return (
    <div className="language-selector">
      {languages.map((language) => (
        <button
          key={language.code}
          onClick={() => changeLanguage(language.code)}
          className={i18n.language === language.code ? "active" : ""}
          style={{ fontFamily: "CustomFont", fontSize: "large" }}
        >
          <img src={language.flag} alt="" />
          {language.name}
        </button>
      ))}
    </div>
  );
}

export default LanguageSelector;
