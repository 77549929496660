import React, { useEffect, useState } from "react";
import Slider from "react-slick";
import { useTranslation } from "react-i18next";

import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";

import { fetchProducts } from "../../api/api";

import "./home.css";

function Home() {
  // const [error, setError] = useState(null);
  const [products, setProducts] = useState([]);
  const [selectedProduct, setSelectedProduct] = useState(null);
  const [enlargedImage, setEnlargedImage] = useState(null);
  const { t, i18n } = useTranslation("global");

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    swipe: false,
    slidesToShow: 2,
    slidesToScroll: 1,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1300,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          infinite: true,
          dots: false,
        },
      },
      {
        breakpoint: 800,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  useEffect(() => {
    const fetchProductsAsync = async () => {
      try {
        const data = await fetchProducts();
        setProducts(data);
      } catch (error) {
        // setError(error);
      }
    };
    fetchProductsAsync();
  }, []);

  const languageToFieldMappingName = {
    en: "name",
    de: "name_DE",
    fr: "name_FR",
    ro: "name_RO",
  };

  const languageToFieldMappingDescription = {
    en: "description",
    de: "description_DE",
    fr: "description_FR",
    ro: "description_RO",
  };

  const languageToFieldMappingSize = {
    en: "size",
    de: "size_DE",
    fr: "size_FR",
    ro: "size_RO",
  };

  const selectedLanguage = i18n.language;
  const nameField = languageToFieldMappingName[selectedLanguage];
  const descriptionField = languageToFieldMappingDescription[selectedLanguage];
  const sizeField = languageToFieldMappingSize[selectedLanguage];

  const openPopup = (product) => {
    setSelectedProduct(product);
  };

  const closePopup = () => {
    setSelectedProduct(null);
  };

  const handleImageClick = (img) => {
    if (window.innerWidth > 768) {
      setEnlargedImage(img);
    }
  };

  const handleCloseEnlarged = () => {
    setEnlargedImage(null);
  };

  // if (error) {
  //   return (
  //     <div>
  //       <h1>{error.message}</h1>
  //     </div>
  //   );
  // }

  return (
    <div className="home-container">
      <div className="hero">
        <div className="hero-text">
          <p>{t("home.welcome")}</p>
        </div>
      </div>
      <div className="product-container">
        {products.map((product) => (
          <div key={product.id} className="product-item">
            <img
              src={product.attributes.main_img_url}
              alt={product.attributes.name}
              onClick={() => openPopup(product)}
              // style={{ width: "70vw", height: "43vh" }}
            />
          </div>
        ))}
        {selectedProduct && (
          <div className="popup">
            <button className="close-button" onClick={closePopup}>
              <strong>X</strong>
            </button>
            <h2>{selectedProduct.attributes[nameField]}</h2>
            <div className="popup-content">
              {window.innerWidth <= 768 ? (
                selectedProduct.attributes.imgs_url
                  .split("\n")
                  .map((img, index) => (
                    <div className="card-img" key={index}>
                      <div className="image-container">
                        <img
                          src={img}
                          alt={selectedProduct.attributes.name}
                          height={400}
                          width={600}
                          onClick={() => handleImageClick(img)}
                        />
                      </div>
                    </div>
                  ))
              ) : (
                <Slider {...settings}>
                  {selectedProduct.attributes.imgs_url
                    .split("\n")
                    .map((img, index) => (
                      <div className="card-img" key={index}>
                        <div className="image-container">
                          <img
                            src={img}
                            alt={selectedProduct.attributes.name}
                            height={400}
                            width={600}
                            onClick={() => handleImageClick(img)}
                          />
                        </div>
                      </div>
                    ))}
                </Slider>
              )}
              {enlargedImage && (
                <div
                  className="enlarged-image-overlay"
                  onClick={handleCloseEnlarged}
                >
                  <div className="enlarged-image-container">
                    <img
                      src={enlargedImage}
                      alt={selectedProduct.attributes.name}
                      height={650}
                      width={900}
                    />
                  </div>
                </div>
              )}
              <div
                className="description-container"
                style={{ whiteSpace: "pre-line" }}
              >
                {selectedProduct.attributes[descriptionField]}
              </div>
              <div
                className="style-container"
                style={{ whiteSpace: "pre-line" }}
              >
                {selectedProduct.attributes[sizeField]}
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default Home;
