import React from "react";
import Map from "../map/map";

import "./contact.css";

function Contact() {
  const recipientEmail = "internationalwoodproduct@gmail.com";
  const subject = "Subject of the email";
  const body = "Hello, \n\nI hope this email finds you well.";

  const mailtoUrl = `mailto:${recipientEmail}?subject=${encodeURIComponent(
    subject
  )}&body=${encodeURIComponent(body)}`;

  function handleFacebookImg() {
    window.open("https://www.facebook.com/profile.php?id=100087556035688");
  }

  function handleInstagramImg() {
    window.open("https://www.instagram.com/internationalwood.prod/");
  }

  function handleWhatsAppImg() {
    window.open("https://wa.me/+40751847394");
  }

  return (
    <div className="contact-container">
      <div className="card social-container">
        <h2>Socials:</h2>
        <div>
          <img
            src={require("./facebook.png")}
            alt="facebook"
            height={100}
            width={250}
            onClick={handleFacebookImg}
          />
        </div>
        <div>
          <img
            src={require("./insta.png")}
            alt="insta"
            height={120}
            width={250}
            onClick={handleInstagramImg}
          />
        </div>
        <div>
          <img
            src={require("./whatsapp.png")}
            alt="whatsapp"
            height={100}
            width={250}
            onClick={handleWhatsAppImg}
          />
        </div>
        <h2>E-mail:</h2>
        <br />
        <div>
          Write us an e-mail on: <br />
          <a href={mailtoUrl} target="blank">
            internationalwoodproduct@gmail.com
          </a>
          .
        </div>
        <br />
      </div>
      <div className="card map-container">
        <h2>Location:</h2>
        <div>Hododului nr. 8, Cehu Silvaniei, Romania</div>
        <Map />
      </div>
    </div>
  );
}

export default Contact;
