import React from "react";
import { useTranslation } from "react-i18next";

import "./about.css";

function About() {
  const { t } = useTranslation("global");

  return (
    <div className="about-container">
      <img className="company-img" src="https://imgur.com/PuagRUz.jpg" alt="" />
      <div>
        <p>{t("about.text1")}</p>
      </div>
      <div>
        <p>{t("about.text2")}</p>
      </div>
      <div>
        <p>{t("about.text3")}</p>
      </div>
      <div>
        <p>{t("about.text4")}</p>
      </div>
    </div>
  );
}

export default About;
