import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import LanguageSelector from "./components/language-selector/language-selector";

import "./App.css";
import Home from "./components/home/home";
import Contact from "./components/contact/contact";
import About from "./components/about/about";

function App() {
  const [selectedTab, setSelectedTab] = useState("home");
  const [t] = useTranslation("global");
  const [isNavBarVisible, setNavBarVisible] = useState(true);

  const handleTabClick = (tab) => {
    setSelectedTab(tab);
  };

  const toggleNavBar = () => {
    setNavBarVisible(!isNavBarVisible);
  };

  return (
    <div className="App">
      <div
        className="nav-bar"
        style={{ display: isNavBarVisible ? "" : "none" }}
      >
        <div className="left-section">
          <img src={require("./logo.png")} alt="" height={"42"} />
        </div>
        <div className="middle-section">
          <button
            className={`tab ${selectedTab === "home" ? "active" : ""}`}
            onClick={() => handleTabClick("home")}
            style={{
              fontFamily: "CustomFont",
              fontSize: "large",
            }}
          >
            {t("header.home")}
          </button>
          <span className="tab-button-selector"> | </span>
          <button
            className={`tab ${selectedTab === "contact" ? "active" : ""}`}
            onClick={() => handleTabClick("contact")}
            style={{ fontFamily: "CustomFont", fontSize: "large" }}
          >
            {t("header.contact")}
          </button>
          <span className="tab-button-selector"> | </span>
          <button
            className={`tab ${selectedTab === "about" ? "active" : ""}`}
            onClick={() => handleTabClick("about")}
            style={{ fontFamily: "CustomFont", fontSize: "large" }}
          >
            {t("header.about")}
          </button>
        </div>
        <div className="right-section">
          <LanguageSelector />
        </div>
      </div>
      <div
        className="toggle-navbar-container"
        style={{
          width: !isNavBarVisible ? "100%" : "33%",
          backgroundColor: !isNavBarVisible ? "rgba(46, 49, 52, 0.6)" : "",
        }}
      >
        <button
          className="toggle-button"
          onClick={toggleNavBar}
          style={{ paddingLeft: !isNavBarVisible ? "3%" : "9%" }}
        >
          <div></div>
          <div></div>
          <div></div>
        </button>
      </div>
      {selectedTab === "home" ? (
        <div className="home-content">
          <Home />
        </div>
      ) : selectedTab === "contact" ? (
        <div className="contact-contet">
          <Contact className="contact" />
        </div>
      ) : (
        <div className="about-content">
          <About />
        </div>
      )}
    </div>
  );
}

export default App;
